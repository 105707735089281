import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
    Button
} from 'reactstrap';

// Actions
import { set_header } from '../../actions/header';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// services
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import UserService from '../../services/UserService';

// Components
import DealerForm from './DealerForm';
import Notifications from '../Notifications';

function AddDealerPage({ set_header }) {
    const navigate = useNavigate();

    const userId = UserService.getUserId();

    const formValidation = (dealer) => {
        if (dealer['name'] === "" || dealer['address'] === '' || dealer['zip'] === '' || 
            dealer['city'] === '' || dealer['state'] === '' || dealer['state'] === null ||
            dealer['territory'] === {} || isNaN(Number(dealer['netSuiteId']))) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        set_header('Dealers', 'NEW')
    }, [set_header])

    const onSubmit = (dealer) => {
        if (formValidation(dealer)) {
            DealerService.createDealer({
                ...dealer,
                territoryId: dealer.territory ? dealer.territory.value : '',
                groupId: dealer.dealerGroup ? dealer.dealerGroup.value : ''
            }).then(response => {

                const dealerPayload = {
                    dealerId: response.data.id,
                    userIds: [
                        userId
                    ]
                }

                DealerService.linkUserToDealer(dealerPayload)
                    .then(linkResponse => {
                        navigate(`/dealers/${response.data.id}?tab=info`)
                        Notifications("success", "Dealer has been created.")
                    }).catch(err => {
                        Notifications('error', err['message'])
                    })
            })
            .catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
        }
    };

    return (
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='details-page__header'>
                        <div className='vertical-align-div'>
                            <Link to='/dealers'>
                                <Button>
                                    <img src={leftChevron} alt="Back"/>
                                </Button>
                            </Link>
                        </div>
                        <div className='details-page__menu'>
                            <button 
                                className='filter-buttons active'
                            >
                                Info
                            </button>
        {/*                    <button 
                                className='filter-buttons'
                            >
                                Products
                            </button>
                            <button 
                                className='filter-buttons'
                            >
                                Pricing Position
                            </button>
                            <button 
                                className='filter-buttons'
                            >
                                History
                            </button>*/}
                        </div>
                    </div>
                </div>
                <div className="content-row flex-grow">
                    <DealerForm 
                        onSubmit={onSubmit}
                    />
                </div>
           
                <div className="content-row footer">
                    <Link to='/dealers'>
                        <Button
                            className="footer__cancel-button"
                        >
                            <div className="vertical-align-div">
                                <span>Cancel</span>
                            </div>
                        </Button>
                    </Link>
                    <Button
                        className="footer__save-button"
                        form='dealer-form'
                        type='submit'
                    >
                        <div className="vertical-align-div">
                            <span>Save Info</span>
                        </div>
                        <div className="vertical-align-div">
                            <img src={save} alt="Save"/>
                        </div>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(AddDealerPage);