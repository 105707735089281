import moment from 'moment';

export const set_contract_status = (status= null) => ({
    type: 'SET_CONTRACT_STATUS',
    status
});

export const set_contract_vin = (vin = '') => ({
    type: 'SET_CONTRACT_VIN',
    vin
});
export const set_contract_dealer = (dealer = {}) => ({
    type: 'SET_CONTRACT_DEALER',
    dealer
});
export const set_contract_activation_start_date = (activationStartDate = moment()) => ({
    type: 'SET_CONTRACT_ACTIVATION_START_DATE',
    activationStartDate
});
export const set_contract_activation_end_date = (activationEndDate = moment()) => ({
    type: 'SET_CONTRACT_ACTIVATION_END_DATE',
    activationEndDate
});
export const set_contract_updated_start_date = (updatedStartDate = moment()) => ({
    type: 'SET_CONTRACT_UPDATED_START_DATE',
    updatedStartDate
});
export const set_contract_updated_end_date = (updatedEndDate = moment()) => ({
    type: 'SET_CONTRACT_UPDATED_END_DATE',
    updatedEndDate
});
export const set_contract_page = (page = 1) => ({
    type: 'SET_CONTRACT_PAGE',
    page
});
export const set_contract_id = (contractId = '') => ({
    type: 'SET_CONTRACT_ID',
    contractId
});
export const set_contract_customer = (contractCustomer= '') => ({
    type: 'SET_CONTRACT_CUSTOMER',
    contractCustomer
});
export const set_contract_product_name = (contractProductName = '') => ({
    type: 'SET_CONTRACT_PRODUCT_NAME',
    contractProductName
});
export const set_contract_product_type = (contractProductType = {}) => ({
    type: 'SET_CONTRACT_PRODUCT_TYPE',
    contractProductType
});