import {
    NotificationManager
} from 'react-notifications';

import 'react-notifications/lib/notifications.css';

export default (type, message) => {
    switch (type) {
        case 'info':
            return NotificationManager.info(message, 'Info');
        case 'vinInfo':
            return NotificationManager.info(message, 'VIN Validation Failed');
        case 'error':
            return NotificationManager.error(message, 'Error')
        case 'longError':
            return NotificationManager.error(message, 'Error', 10000)
        case 'success':
            return NotificationManager.success(message, 'Success')
    };
};