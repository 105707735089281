import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import {
    Button,
    ButtonGroup
} from 'reactstrap';

// images
import greyX from '../../../images/grey-x.svg';

// components
import Notifications from '../../Notifications';

// services
import AuthService from '../../../services/AuthService';
import AdditionalItemsService from '../../../services/AdditionalItemsService';

function EditAdditionalItemModal({ isOpen, toggleMenu, additionalItem }) {
    const navigate = useNavigate();

    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [netSuiteId, setNetSuiteId] = useState(null);
    const [sendToNetSuite, setSendToNetSuite] = useState(true)

    useEffect(() => {
        if (additionalItem) {
            setCode(additionalItem.code)
            setDescription(additionalItem.description)
            setNetSuiteId(additionalItem.netSuiteId)
            setSendToNetSuite(additionalItem.sendToNetSuite)
        }
    }, [additionalItem])

    const onSendNetSuiteClick = (send) => {
        setSendToNetSuite(send)
    }

    const handleSaveClick = () => {
        let validated = true;

        if (code === "") {
            Notifications("error", "Code is required.")
            validated = false;
        }
        if (description === "") {
            Notifications("error", "Description is required.")
            validated = false;
        }
        if (isNaN(Number(netSuiteId))) {
            Notifications('error', 'Please enter a numerical value for NetSuite ID.')
            validated = false;
        }

        if (validated) {
            const updatedAdditionalItem = {
                id: additionalItem.id,
                code: code,
                description: description,
                netSuiteId: netSuiteId ? parseInt(netSuiteId) : null,
                sendToNetSuite: sendToNetSuite
            }
            AdditionalItemsService.updateAdditionalItem(
                updatedAdditionalItem
            ).then(response => {
                Notifications('success', 'Additional Item has been updated.')

                toggleMenu(updatedAdditionalItem)
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => toggleMenu()}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className='modal-additional-item'
        >   
            <div className='modal-body'>
                <div className='modal-header'>
                    <h2><b>Edit Additional Item</b></h2>
                    <button className='close' onClick={() => toggleMenu()}>
                        <img src={greyX} alt="Close"/>
                    </button>
                </div>
                <div className="details">
                    <div>
                        <span>
                            Additional Item Code
                        </span>
                        <input 
                            type='text'
                            placeholder='Enter Additional Item Code'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className='input input__form'
                        />
                    </div>
                    <div>
                        <span>
                            Description
                        </span>
                        <input 
                            type='text'
                            placeholder='Enter Description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className='input input__form'
                        />
                    </div>
                    <div>
                        <span>
                            NetSuite ID
                        </span>
                        <input 
                            type='text'
                            placeholder='Enter NetSuite ID'
                            value={netSuiteId}
                            onChange={(e) => setNetSuiteId(e.target.value)}
                            className='input input__form'
                        />
                    </div>
                    <div className='details-button'>
                        <span>
                            Send to NetSuite
                        </span>
                        <ButtonGroup>
                            <Button 
                                onClick={() => onSendNetSuiteClick(true)}
                                active={sendToNetSuite===true}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => onSendNetSuiteClick(false)}
                                active={sendToNetSuite===false}
                            >
                                No
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    className='link__form-add'
                    onClick={handleSaveClick}
                >
                    Save
                </button>
            </div>
        </Modal>
    );
};

export default EditAdditionalItemModal;