import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import moment from 'moment';

// Components
import LoadingPage from '../LoadingPage';

// services
import AuthService from '../../services/AuthService';
import HistoryService from '../../services/HistoryService';

// images
import history from '../../images/history-log.svg';

function ContractHistoryPage(props) {

    const { id } = useParams();
    const navigate = useNavigate();

    const [historyLog, setHistoryLog] = useState([])
    const [successful, setSuccessful] = useState(false)

    const [vendorInfo, setVendorInfo] = useState('')

    useEffect(() => {
        HistoryService.getHistory('contract', id)
            .then(response => {
                let logEntries = []

                response.data.reverse().forEach((entry) => {
                    let tempLogs = []
                    
                    if (entry.action === 'contractCreate') {
                        tempLogs.push('Contract created')
                    } else if (entry.action === 'contractUpdateStatus') {
                        tempLogs.push(`Contract ${JSON.parse(entry.description).status}`)
                    } else if (entry.action === 'contractUpdateOwner') {
                        tempLogs.push(`Contract transferred`)
                    } else if (entry.action === 'externalContractCreate') {
                        tempLogs.push(`Contract created`)
                    } else if (entry.action === 'externalContractFinalize') {
                        tempLogs.push(`Contract finalized`)
                    } else if (entry.action === 'externalContractVoid') {
                        tempLogs.push(`Contract cancelled`)
                    } 

                    tempLogs.forEach((log) => {
                        logEntries.push({
                            title: log,
                            author: entry.author.split(":")[0],
                            date: moment(entry.createdAt).format('MM/DD/yyyy'),
                            time: moment(entry.createdAt).format('LT')
                        })
                    })
                })
                
                setHistoryLog(logEntries)
                setSuccessful(true)

            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                setSuccessful(false)
            })
    }, [id, navigate])

    useEffect(() => {
        if (props.contract.vendorInfo !== null ) {
            const managerDetails = props.contract.vendorInfo.split(',')
            const name = managerDetails[0]
            const email = managerDetails[1]
            if (name === '') {
                setVendorInfo(email)
            } else if (email === '') {
                setVendorInfo(name)
            } else {
                setVendorInfo(name.concat(', ' + email))
            }
        }
    }, [props.contract])

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="content-row flex-grow">
                <div className="details-page__form" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    {
                        historyLog.map(log => (
                            <div className='history-record' key={`${log['title']}-${log['author']}-${log['date']}-${log['time']}`}>
                                <div className='history-details'>
                                    <button
                                        className='history-icon'
                                        disabled={true}
                                    >
                                        <img src={history} alt="history"/>
                                    </button>
                                    <div className='history-text'>
                                        <p className='history-text__title'>{log.title}</p>
                                        <p className='history-text__subtitles'> 
                                            By: {
                                                log.title === 'Contract created' && props.contract.vendorInfo !== null ? (
                                                    <span>{log.author} ({vendorInfo})</span>
                                                ) : (
                                                    <span>{log.author}</span>
                                                )
                                            }
                                        </p>
                                        <div className='history-text__date'>
                                            <p>{log.date}</p>
                                            •
                                            <p>{log.time}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="history-expand">
                                    {/*<button
                                        className='history-expand-button'
                                        disabled={true}
                                    >
                                        See Details >
                                    </button>*/}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default ContractHistoryPage;