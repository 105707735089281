import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// components
import LoadingPage from '../LoadingPage';
import TransferContractOwnerModal from './TransferContractOwnerModal';
import Notifications from '../Notifications';
import BulkApproveContractsModal from './BulkApproveContractsModal';
// table
import { useTable, useSortBy } from "react-table";
// services
import ContractService from '../../services/ContractService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
// other
import moment from 'moment';
import Numeral from 'numeral';
// icons
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';
import print from '../../images/print.svg';
import contractApprove from '../../images/contract-approve.svg';
import contractTransfer from '../../images/contract-transfer.png';
import gear from '../../images/gear.svg';
import edit from '../../images/edit.svg';



function ContractsTable({ 
    manualPagination = false, currentPage, handlePageData, filters, applyResetTable}) {

    const [initialRender, setInitialRender] = useState(true)

    const navigate = useNavigate();
    let location = useLocation();
    const userRole = UserService.getUserRole();
    const [searchParams, setSearchParams] = useSearchParams(); 
    const contractId = searchParams.get('contractId');
    // modals
    const [bulkApproveContractsModalOpen, setBulkApproveContractsModalOpen] = useState(false)
    const [selectedContractTransfer, setSelectedContractTransfer] = useState({})
    const [transferModalOpen, setTransferModalOpen] = useState(false)
    // 
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalContracts: 0
    })
    const [sorting, setSorting] = useState({
        sortBy: 'activationDate',
        orderBy: 'desc'
    })

    useEffect(() => {

        if (initialRender) {
            setInitialRender(false)
        }

        setPageData((prev) => ({
            ...prev,
            rowData: [],
            loading: true
        }))

        ContractService.getAllContracts(
            filters.idSearchFilter === '' && filters.vinSearchFilter === '' ? filters.activationStartDateDay.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).toISOString() : null,
            filters.idSearchFilter === '' && filters.vinSearchFilter === '' ? filters.activationEndDateDay.utcOffset(0).set({hour:23,minute:59,second:59,millisecond:599}).toISOString() : null,
            filters.updatedStartDateDay !== null ? filters.updatedStartDateDay.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).toISOString() : null,
            filters.updatedEndDateDay !== null ? filters.updatedEndDateDay.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).toISOString() : null,
            currentPage,
            filters.contractTypeFilter === '' ? '' : filters.contractTypeFilter,
            filters.idSearchFilter === '' ? '' : filters.idSearchFilter,
            filters.customerSearchFilter === '' ? '' : filters.customerSearchFilter,
            filters.dealerSearchFilter === null ?  null : filters.dealerSearchFilter.id,
            filters.vinSearchFilter === '' ? null : filters.vinSearchFilter,
            sorting.sortBy,
            sorting.orderBy
            ).then(response => {
                filterContracts(response)
            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e['message'])
            })

    }, [currentPage, filters, sorting])


    // filter table on reset button
    useEffect(() => {
        if (!initialRender) {
            setPageData(prev => ({
                ...prev,
                loading: true
            }));
            setSorting({
                sortBy: 'activationDate',
                orderBy: 'desc'
            })
            const currentDate = new Date()
            const startDate = moment(currentDate).subtract(30, 'days')
            const startDateDay = startDate.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).toISOString()
            const endDateDay = moment(currentDate).utcOffset(0).set({hour:23,minute:59,second:59,millisecond:599}).toISOString()
    
            ContractService.getAllContracts(
                startDateDay, 
                endDateDay, 
                null, 
                null, 
                1, 
                null, 
                null, 
                null, 
                null, 
                null, 
                'activationDate', 
                'desc'
            )
                .then(response => {
                    filterContracts(response)
                }, e => {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", e['message'])
                })
        }
        
    }, [applyResetTable])

    const filterContracts = (response) => {
        const { data, meta } = response
        const tableData = data.map(contract => {
            const ownerInfo = JSON.parse(contract.owner)
            return ({
                id: contract.id,
                selected: false,
                dealer: contract.dealer,
                product: contract.pricingPositions[0]['title'],
                term: contract.pricingPositions[0]['term'],
                activationDate: moment(contract.activationDate).utc().format('YYYY-MM-DD'),
                vin: contract.vin,
                name: `${ownerInfo.firstName} ${ownerInfo.lastName}`,
                totalPrice: contract.pricingPositions.reduce((sum, price) => sum + parseFloat(price.price.slice(-1)), 0),
                sellingPrice: contract.sellingPrice,
                status: contract.status.replace('_', ' '),
                actions: checkUserActions(contract),
                edit: <Link to={`/contracts/${contract.id}?tab=info`}><img src={edit} alt="Edit"/></Link>
            })
        })

        const contractsData = {
            rowData: tableData,
            loading: false,
            totalPages: meta.totalPages,
            totalContracts: meta.totalItems
        }
        setPageData(contractsData)
        handlePageData(contractsData)
    };

    //  load modals for specific URLs
    useEffect(() => {
        if (location.pathname.includes('/approveall')) {
            setBulkApproveContractsModalOpen(true)
        }
    }, [location])

   

     /*                url-related modals                       */
     const toggleBulkApproveContractsModal = () => {
        setBulkApproveContractsModalOpen(current => {
            return !current
        })
    };


    /*                      table buttons                   */
    const handleTransferOwner = (e, contract) => {

        if (['SUPERADMIN', 'METROTECH_ADMIN'].includes(userRole)) {
            e.stopPropagation()
        
            setSelectedContractTransfer(contract)
            setTransferModalOpen(true)
        }
        
    };

    const toggleTransferModal = () => {
        setTransferModalOpen(current => {
            return !current
        })
    };

    const formValidation = (owner) => {
        const ownerParse = JSON.parse(owner)
        if (ownerParse['firstName'] === '' || ownerParse['lastName'] === '' || ownerParse['ownerType'] === '') {
            return false;
        }
        return true;
    }
    
    const getPDFLink = (contract) => {
        Notifications('info', 'Download has started...')

        ContractService.getContractPDF(contract.id)
            .then(response => {
                Notifications('success', 'Contract downloaded.')
            }, e => {
                Notifications('error', 'Unable to download contract.')
            })
    };

    const handleStatusChange = (e, contract, action) => {
        if (e !== null) { 
            e.stopPropagation()
        }

        ContractService.updateContractStatus({
            id: contract.id,
            action: action
        })
        .then(response => {

            setPageData(prev => ({
                ...prev,
                rowData: prev.rowData.map(contract => {
                    if (contract.id === response.data.id) {
                        return {
                            ...contract,
                            status: response.data.status.replace('_', ' '),
                            actions: checkUserActions(response.data)
                        }
                    } else {
                        return contract
                    }
                })
            }))

            if (action === 'approve') {
                Notifications("success", 'Contract has been approved.')
            } else if (action === 'cancel') {
                Notifications("success", 'Contract has been cancelled.')
            }
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            Notifications("error", e['message'])
        })
    };

    const checkUserActions = (contract) => {
        return (
            <div className="contracts-action__div">
                {
                    ((contract.status === 'NON_FINALIZED' && ['SUPERADMIN', 'METROTECH_ADMIN', 'DEALERSHIP_ADMIN', 'DEALERSHIP_ACCOUNTING'].includes(userRole)) || 
                    (contract.status === 'PENDING' && ['SUPERADMIN', 'METROTECH_ADMIN'].includes(userRole))) &&
                        <button
                            onClick={(e) => handleStatusChange(e, contract, 'approve')}
                            className='button-no-background'
                        >
                            <div>
                                <img src={contractApprove} title="Approve" alt="Approve"/>
                            </div>
                        </button>
                }
                {
                    ['NON_FINALIZED', 'AUTO_FINALIZED', 'MANUAL_FINALIZED'].includes(contract.status) && ['SUPERADMIN', 'METROTECH_ADMIN'].includes(userRole) &&
                        <button
                            onClick={(e) => handleTransferOwner(e, contract)}
                            className='button-no-background'
                        >
                            <div>
                                <img src={contractTransfer} title="Transfer" alt="Transfer"/>
                            </div>
                        </button>
                }
                {
                    contract.status !== 'PENDING' &&
                        <button 
                            onClick={() => getPDFLink(contract)}
                            className='button-no-background'
                        >
                            <div>
                                <img src={print} alt="Print"/>
                            </div>
                        </button>
                }
            </div>
        )
    };

    /*                  submit functions                    */
    const onSubmitTransfer = (owner) => {
        if (formValidation(owner)) {
            ContractService.updateContractOwner({
                id: selectedContractTransfer.id,
                owner: owner
            })
                .then(response => { 
                    const newOwner = JSON.parse(response.data.owner)

                    setPageData(prev => ({
                        ...prev,
                        rowData: prev.rowData.map(contract => {
                            if (contract.id === response.data.id) {
                                return {
                                    ...contract,
                                    name: `${newOwner.firstName} ${newOwner.lastName}`,
                                    comments: ''
                                }
                            } else {
                                return contract
                            }
                        })
                    }))

                    handleStatusChange(null, response.data, 'transfer')
                    Notifications('success', 'Contract has been transferred.')
                    toggleTransferModal()
                }, e => {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", e['message'])
                });
        }
    };
    const onSubmitBulkApproveContractsModal = (message) => {
        if (message === 'success') {
            ContractService.finalizePendingContracts()
            .then(response => {
                response.data.forEach((finalizedContract) => {

                    setPageData(prev => ({
                        ...prev,
                        rowData: prev.rowData.map(contract => {
                            if (finalizedContract.id === contract.id) {
                                return {
                                    ...contract,
                                    status: finalizedContract.status.replace('_', ' '),
                                    actions: checkUserActions(finalizedContract)
                                }
                            } else {
                                return contract
                            }
                        })
                    }))

                })
                toggleBulkApproveContractsModal()
                navigate('/contracts')
                Notifications(message, 'You have successfully finalized all pending contracts.')
            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications('error', e['message'])
            })
        } else {
            toggleBulkApproveContractsModal()
            navigate('/contracts')
        }
    };
    
    const handleSortingChange = (column) => {
        setSorting(prev => {
            return {
                sortBy: column,
                orderBy: prev.sortBy === column && prev.orderBy === 'asc' ? 'desc' : 'asc'
            }
        })
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell; 

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Dealer',
                accessor: 'dealer',
                Cell: ({ cell }) => {
                    const { value } = cell; 

                    return (
                        <div className="table-cell__div">
                            {value.name}
                        </div>
                    );
                }
            },
            {
                Header: 'Product',
                accessor: 'product',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'VIN',
                accessor: 'vin',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }

            },
            {
                Header: 'Activation Date',
                accessor: 'activationDate',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Selling Price',
                accessor: 'sellingPrice',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {Numeral(value).format("$0,0.00")}
                        </div>
                    );
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let badgeClassName = '';
                    let words = value.toLowerCase().split(' ');

                    const badgeText = words.map((word) => { 
                        return word[0].toUpperCase() + word.substring(1); 
                    }).join(" ");

                    if (badgeText === 'Cancelled') {
                        badgeClassName = 'badge__cancelled'
                    } else if (badgeText === 'Pending' || badgeText === 'Non Finalized') {
                        badgeClassName = 'badge__pending'
                    } else if (badgeText === 'Auto Finalized' || badgeText === 'Manual Finalized') {
                        badgeClassName = 'badge__finalized'
                    } else if (badgeText === 'Transferred') {
                        badgeClassName = 'badge__transferred'
                    }

                    return (
                        <div className="table-cell__div">
                            <div className={`badge ${badgeClassName}`}>
                                {badgeText}
                            </div>
                        </div>
                    );
                }

            },
            {
                Header: 'Actions',
                accessor: 'actions',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: <img src={gear} alt="Edit"/>,
                accessor: 'edit',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], [sorting]
    )

    /*                      filter functions                   */

    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable(
            {
                columns,
                data,
                manualPagination,
                manualSortBy: true,
                initialState: {
                    sortBy: [
                        {
                            id: sorting.sortBy,
                            desc: sorting.orderBy === 'desc'
                        }
                    ]
                }
            },
            useSortBy
        );

        const generateSortingIndicator = (column) => {
            if (column.canSort) {

                const currentSortedColumn = column.id === sorting.sortBy
                const isAscending = sorting.orderBy === 'asc'

                return (
                    <div onClick={() => handleSortingChange(column.id)}>
                        {
                            currentSortedColumn ? (
                                isAscending ? (
                                    <img src={ascending} className='table-header__icon-img' alt='Ascending'></img>
                                ) : (
                                    <img src={descending} className='table-header__icon-img' alt='Descending'></img>
                                )
                            ) : (
                                <img  src={sort} className='table-header__icon-img' alt='Sort'></img>
                            )
                        }
                    </div>
                )
            } else {
                return null
            }
        };

        const generateVerticalLine = (column) => {
            if (column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }


        return (
            <React.Fragment>
                <div className='table-div'>
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column) => (
                                                <th className='table-header'{...column.getHeaderProps()}>
                                                    <div className='table-header__div'>
                                                        {column.render("Header")}
                                                        <div className='table-header__icons'>
                                                            <div>
                                                                {generateVerticalLine(column)}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </th>

                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="table-row">
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className="flex-end">{cell.render("Cell")}</td>;
                                        })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>  
            </React.Fragment>
        )
    }

    



    return (
        <React.Fragment>
            {
                pageData.loading ? (
                    <LoadingPage />
                ) : (
                    <div className='table'>
                        <Table columns={columns} data={pageData.rowData}/>
                        {
                            manualPagination && (
                                <div>
                                    {}
                                </div>
                            )
                        }
                    </div>
                )
            }

            <TransferContractOwnerModal 
                isOpen={transferModalOpen}
                toggleModal={toggleTransferModal}
                onSubmitTransfer={onSubmitTransfer}
            />
            <BulkApproveContractsModal 
                isOpen={bulkApproveContractsModalOpen}
                toggleBulkApproveContractsModal={toggleBulkApproveContractsModal}
                onSubmitBulkApproveContractsModal={onSubmitBulkApproveContractsModal}
            />

            
        </React.Fragment>
    );
};

export default ContractsTable;

