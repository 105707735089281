import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// components
import LoadingPage from '../LoadingPage';
import Notifications from '../Notifications';
// table
import { useTable, useSortBy } from "react-table";
// services
import DealerService from '../../services/DealerService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
// icons
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';
import gear from '../../images/gear.svg';
import edit from '../../images/edit.svg';



function UsersTable({ 
    manualPagination = false, currentPage, handlePageData, handleCSVData, filters, applyResetTable}) {

    const [initialRender, setInitialRender] = useState(true)

    const navigate = useNavigate();
    let location = useLocation();
    const userRole = UserService.getUserRole();
    const [searchParams, setSearchParams] = useSearchParams(); 
    const contractId = searchParams.get('contractId');
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalUsers: 0
    })
    const [sorting, setSorting] = useState({
        sortBy: 'name',
        orderBy: 'asc'
    })


    useEffect(() => {
        if (initialRender) {
            setInitialRender(false)
        }
        setPageData((prev) => ({
            ...prev,
            rowData: [],
            loading: true
        }))
        UserService.getAllUsers(
            filters.emailFilter === '' ? null : filters.emailFilter,
            filters.roleTypeFilter === null ? null : filters.roleTypeFilter.value,
            currentPage,
            sorting.sortBy,
            sorting.orderBy
        )
            .then(response => {
                filterUsers(response)

                UserService.getAllUsers(
                    filters.emailFilter === '' ? null : filters.emailFilter,
                    filters.roleTypeFilter === null ? null : filters.roleTypeFilter.value,
                    null,
                    sorting.sortBy,
                    sorting.orderBy
                )
                    .then(r => {
                        handleCSVData(r.data)
                    }, err => {
                        Notifications("error", err['message'])
                    })

            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e['message'])
            });
    }, [currentPage, filters, sorting])

    // filter table on reset button
    useEffect(() => {
        if (!initialRender) {
            setPageData(prev => ({
                ...prev,
                loading: true
            }));
            setSorting({
                sortBy: 'name',
                orderBy: 'asc'
            })
            UserService.getAllUsers(null, null, 1, 'name', 'asc')
                .then(response => {
                    filterUsers(response)
                }, e => {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", e['message'])
                })
        }
        
    }, [applyResetTable])

    const filterUsers = (response) => {
        const { data, meta } = response

        const tableData = data.map(user => ({
            id: user.id,
            selected: false,
            email: user.email,
            title: user.title,
            name: user.name + ' ' + user.surname,
            role: user.role,
            phone: user.phone,
            ext: user.ext,
            edit: <Link to={`/users/${user.id}?tab=info`}><img src={edit} alt="Edit"/></Link>
        }))

        const usersData = {
            rowData: tableData,
            loading: false,
            totalPages: meta.totalPages,
            totalUsers: meta.totalItems
        }
        setPageData(usersData)
        handlePageData(usersData)
    };

    const handleSortingChange = (column) => {
        setSorting(prev => {
            return {
                sortBy: column,
                orderBy: prev.sortBy === column && prev.orderBy === 'asc' ? 'desc' : 'asc'
            }
        })
    };

    const columns = React.useMemo(
        () => [

            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let words = value.toLowerCase().split('_');

                    const badgeText = words.map((word) => { 
                        return word[0].toUpperCase() + word.substring(1); 
                    }).join(" ");

                    return (
                        <div className="table-cell__div">
                            <div className={`badge badge__user-role`}>
                                {badgeText}
                            </div>
                        </div>
                    );
                }
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Ext',
                accessor: 'ext',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: <img src={gear} alt="Setting"/>,
                accessor: 'edit',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], [sorting]
    )

    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable(
            {
                columns,
                data,
                manualPagination,
                manualSortBy: true,
                initialState: {
                    sortBy: [
                        {
                            id: sorting.sortBy,
                            desc: sorting.orderBy === 'desc'
                        }
                    ]
                }
            },
            useSortBy
        );

        const generateSortingIndicator = (column) => {
            if (column.canSort) {

                const currentSortedColumn = column.id === sorting.sortBy
                const isAscending = sorting.orderBy === 'asc'

                return (
                    <div onClick={() => handleSortingChange(column.id)}>
                        {
                            currentSortedColumn ? (
                                isAscending ? (
                                    <img src={ascending} className='table-header__icon-img' alt='Ascending'></img>
                                ) : (
                                    <img src={descending} className='table-header__icon-img' alt='Descending'></img>
                                )
                            ) : (
                                <img  src={sort} className='table-header__icon-img' alt='Sort'></img>
                            )
                        }
                    </div>
                )
            } else {
                return null
            }
        };

        const generateVerticalLine = (column) => {
            if (column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

        

        return (
            <React.Fragment>
                <div className='table-div'>
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column) => (
                                                <th className='table-header'{...column.getHeaderProps()}>
                                                    <div className='table-header__div'>
                                                        {column.render("Header")}
                                                        <div className='table-header__icons'>
                                                            <div>
                                                                {generateVerticalLine(column)}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </th>

                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="table-row">
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className="flex-end">{cell.render("Cell")}</td>;
                                        })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>  
            </React.Fragment>
        )
    }





    return (
        <React.Fragment>
            {
                pageData.loading ? (
                    <LoadingPage />
                ) : (
                    <div className='table'>
                        <Table columns={columns} data={pageData.rowData}/>
                        {
                            manualPagination && (
                                <div>
                                    {}
                                </div>
                            )
                        }
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default UsersTable;