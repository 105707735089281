import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    useTable, 
    usePagination, 
    useFilters,
    useExpanded,
    useRowSelect,
    useSortBy
} from 'react-table';

// images
import checkboxEmpty from '../../../images/checkbox-empty.svg';
import checkboxFull from '../../../images/checkbox-full.svg';
import greyX from '../../../images/grey-x.svg';
import gear from '../../../images/gear.svg';
import edit from '../../../images/edit.svg';
import add from '../../../images/add.svg';
import search from '../../../images/search.svg';
import sort from '../../../images/sort.svg';
import ascending from '../../../images/ascending.svg';
import descending from '../../../images/descending.svg';

// components
import LoadingPage from '../../LoadingPage';
import EditAdditionalItemModal from './EditAdditionalItemModal';
import AddAdditionalItemModal from './AddAdditionalItemModal';

// table filters
import { Filter, InputColumnFilter } from '../../TableFilters';

// services
import AuthService from '../../../services/AuthService';
import AdditionalItemsService from '../../../services/AdditionalItemsService';

const defaultRowHeight = 50

function AdditionalItemsManagement() {
    const navigate = useNavigate();
    const tableRef = useRef(null)
    
    const codeFilterRef = useRef();
    const descriptionFilterRef = useRef();

    const [successful, setSuccessful] = useState(false);
    const [calcPageSize, setCalcPageSize] = useState(10)
    const [selectedAdditionalItem, setSelectedAdditionalItem] = useState({
        id: '',
        code: '',
        description: '',
        netSuiteId: null,
        sendToNetSuite: true
    });
    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [editMenuOpen, setEditMenuOpen] = useState(false)
    const [data, setData] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        AdditionalItemsService.getAdditionalItems()
            .then(response => {
                setSuccessful(true)
                setData(response.data.map(item => (
                    {
                        id: item.id,
                        code: item.code,
                        description: item.description,
                        netSuiteId: item.netSuiteId,
                        sendToNetSuite: item.sendToNetSuite,
                        edit: <img className="button" onClick={(e) => handleEditClick(e, item)} src={edit} alt="Edit"/>
                    }
                )))

                if (tableRef.current) {
                    setCalcPageSize(Math.floor(tableRef.current.clientHeight / defaultRowHeight) - 1)
                } else {
                    setCalcPageSize(Math.floor((window.innerHeight * 0.7) / defaultRowHeight))
                }
            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                setSuccessful(false)
            })
    }, [navigate])

    const handleEditClick = (e, additionalItem) => {
        e.stopPropagation()

        setSelectedAdditionalItem(additionalItem)
        setEditMenuOpen(true)
    }

    const toggleAddMenu = (newAdditionalItem) => {
        if (newAdditionalItem) {
            setData(prev => [...prev, 
                {
                    ...newAdditionalItem,
                    edit: <img className="button" onClick={(e) => handleEditClick(e, newAdditionalItem)} src={edit} alt="Edit"/>
                }
            ])
        }

        setAddMenuOpen(prev => !prev)
    };

    const toggleEditMenu = (updatedAdditionalItem) => {
        if (updatedAdditionalItem) {
            setData(prev => prev.map(ai => {
                if (ai.id === updatedAdditionalItem.id) {
                    return {
                        ...ai,
                        code: updatedAdditionalItem.code,
                        description: updatedAdditionalItem.description,
                        netSuiteId: updatedAdditionalItem.netSuiteId,
                        sendToNetSuite: updatedAdditionalItem.sendToNetSuite,
                        edit: <img className="button" onClick={(e) => handleEditClick(e, updatedAdditionalItem)} src={edit} alt="Edit"/>
                    }
                } else {
                    return ai
                }
            }))
        }

        setEditMenuOpen(prev => !prev)
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Additional Item Code',
                accessor: 'code',
                Filter: InputColumnFilter,
                ref: codeFilterRef,
                filter: 'equals',
                id: 'code',
                name: 'code',
                searchHidden: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Description',
                accessor: 'description',
                Filter: InputColumnFilter,
                ref: descriptionFilterRef,
                filter: 'equals',
                id: 'description',
                name: 'description',
                searchHidden: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Send To NetSuite',
                accessor: 'sendToNetSuite',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {
                                value ? 
                                (
                                    <span>Yes</span>
                                ) : (
                                    <span>No</span>
                                )
                            }
                        </div>
                    );
                }
            },
            {
                Header: <img src={gear} alt="Edit"/>,
                accessor: 'edit',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], []
    )

    // const onSelectRow = (row) => {
    //     // setSkipPageReset(true)
    //     setData(prev => (
    //         prev.map(d => {
    //             return {
    //                 ...d,
    //                 selected: d['id'] === row.original.id ? !d['selected'] : d['selected']
    //             }
    //         })
    //     ))
    // }

    const filteredData = () => {
        return data
    }

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page, 
            pageCount,
            gotoPage,
            state: { pageIndex },
        } = useTable(
            {
                columns,
                data,
                initialState: { 
                    defaultPageSize: 10,
                    pageSize: calcPageSize,
                    sortBy: [
                        {
                            id: 'code',
                            desc: false
                        }
                    ]
                },
                sortTypes: {
                    alphanumeric: (row1, row2, columnName) => {
                        const rowOneColumn = row1.values[columnName];
                        const rowTwoColumn = row2.values[columnName];
                        if (isNaN(rowOneColumn)) {
                            return rowOneColumn.toUpperCase() >
                                rowTwoColumn.toUpperCase()
                                ? 1
                                : -1;
                        }
                        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                    },
                },
            },
            useFilters,
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect
        )

        const generateSortingIndicator = (column) => {
            if (column.canSort) {
                return column.isSorted ? 
                    (
                        column.isSortedDesc ? 
                            <img {...column.getSortByToggleProps()} src={descending} className="table-header__icon-img" alt="Descending"/> 
                            : 
                            <img {...column.getSortByToggleProps()} src={ascending} className="table-header__icon-img" alt="Ascending"/>
                    ) 
                    : 
                    <img {...column.getSortByToggleProps()} src={sort} className="table-header__icon-img" alt="Sort"/>;
            } else {
                return null
            }
        };

        const onSearchClick = (column) => {
            column.searchHidden = !column.searchHidden

            column.setFilter(undefined)
        }

        const generateSearchIndicator = (column) => {
            if (column.canFilter && !column.searchHidden) {
                return <img className="table-header__icon-img" src={greyX} onClick={() => onSearchClick(column)} alt="Close"/>
            } else if (column.canFilter) {
                return <img className="table-header__icon-img" src={search} onClick={() => onSearchClick(column)} alt="Search"/>
            } else {
                return null
            }
        }

        const generateVerticalLine = (column) => {
            if (column.canFilter && column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

        const onAllSelectRowClick = () => {
            setData(prev => (
                prev.map(d => {
                    return {
                        ...d,
                        selected: !allSelected
                    }
                })
            ))
            setAllSelected(prev => !prev)
        }

        const range = (start, end) => {
            let length = end - start + 1;

            return Array.from({ length }, (_, idx) => idx + start)
        }

        const currentPageIndex = pageIndex + 1
        const siblingCount = 1
        const totalPageNumbers = siblingCount + 5
        let customRange;

        /*
            Case 1:
            If the number of pages is less than the page numbers we want to show in our
            paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= pageCount) {
            customRange = range(1, pageCount)
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPageIndex - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPageIndex + siblingCount,
            pageCount
        );

        /*
            We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = pageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            customRange = [...leftRange, '...', pageCount];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                pageCount - rightItemCount + 1,
                pageCount
            );
            customRange = [firstPageIndex, '...', ...rightRange];
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            customRange = [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
        }
    
        // Render the UI for your table
        return (
            <React.Fragment>
                <div ref={tableRef} className="table-div">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className={`table-header ${column.canFilter && 'filterable'} flex-end`} {...column.getHeaderProps()}>
                                                    <div className="table-header__div">
                                                        {
                                                            column.id === 'selected' ?
                                                                <img src={allSelected ? checkboxFull : checkboxEmpty} onClick={onAllSelectRowClick} style={{ cursor: 'pointer' }} alt="Select"/>
                                                                :
                                                                (column.canFilter && !column.searchHidden) ? 
                                                                    <Filter column={column} hidden={column.searchHidden}/>
                                                                    :
                                                                    column.render('Header')
                                                        }
                                                        <div className="table-header__icons">
                                                            <div>
                                                                {generateSearchIndicator(column)}
                                                                {generateVerticalLine(column)}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} className="table-row">
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className="flex-end">{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* 
                    Pagination can be built however you'd like. 
                    This is just a very basic UI implementation:
                */}
                <div className="pagination">
                    { 
                        <div className="pagination__table">
                            <div className="vertical-align-div">
                                <button 
                                    className={'pagination__page-button'}
                                    disabled={currentPageIndex === 1}
                                    onClick={() => gotoPage(pageIndex - 1)}
                                >
                                    &lt;
                                </button>
                            </div>
                            {
                                customRange.map((i, idx) => {
                                    if (i === '...') {
                                        return (
                                            <div className="vertical-align-div" key={`dealers-page-index-${idx}`}>
                                                &#8230;
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="vertical-align-div" key={`dealers-page-index-${idx}`}>
                                                <button 
                                                    className={currentPageIndex === i ? 'pagination__page-button active' : 'pagination__page-button'}
                                                    onClick={() => gotoPage(i-1)}
                                                >
                                                    {i}
                                                </button>
                                            </div>
                                        )
                                    }
                                })
                            }
                            <div className="vertical-align-div">
                                <button 
                                    className={'pagination__page-button'}
                                    disabled={currentPageIndex === pageCount}
                                    onClick={() => gotoPage(pageIndex + 1)}
                                >
                                    &gt;
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="content-row flex-right">
                <button
                    className='link__form-add'
                    onClick={() => toggleAddMenu()}
                >
                    <span className="hide-for-mobile">
                        Add New
                    </span>
                    <div className='vertical-align-div'>
                        <img src={add} alt="Add"/>
                    </div>
                </button>
            </div>
            <div className='table'>
                <Table columns={columns} data={filteredData()}/>
            </div>
            <AddAdditionalItemModal
                isOpen={addMenuOpen}
                toggleMenu={toggleAddMenu}
            />
            <EditAdditionalItemModal
                isOpen={editMenuOpen}
                toggleMenu={toggleEditMenu}
                additionalItem={selectedAdditionalItem}
            />
        </React.Fragment>
    );
};

export default AdditionalItemsManagement;


